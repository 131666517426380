$(document).ready(function (e) {
    $('.left__banner').on('init', function (event, slick, direction) {
        if ($('.left__banner .slick-slide').length === 1) {
            $('.left__banner .slick-dots').hide();
        }
    });

    $('.right__banner').on('init', function (event, slick, direction) {
        if ($('.right__banner .slick-slide').length === 1) {
            $('.right__banner .slick-dots').hide();
        }
    });

    $('.left__banner').slick({
        autoplay: true,
        dots: true,
        arrows: false,
    });

    $('.right__banner').slick({
        dots: true,
        arrows: false,
    });

    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        swipe: true,
        rows: 0,
        asNavFor: '.slider-nav',
    });
    $('.slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        infinite: false,
        focusOnSelect: true,
        swipe: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                },
            },
        ],
    });

    $('.slider-for-1').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav-1',
    });
    $('.slider-nav-1').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-for-1',
        focusOnSelect: true,
        dots: false,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                },
            },
        ],
    });

    function sliderLftPhoto() {
        let sliderLftPhoto = $('.slider__lft-photo');
        if (sliderLftPhoto.hasClass('slick-slider'))
            sliderLftPhoto.slick('unslick');

        sliderLftPhoto.slick({
            dots: false,
            arrows: true,
            slidesToShow: 4,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1026,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        speed: 0,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        speed: 0,
                    },
                },
                {
                    breakpoint: 768,
                    settings: 'unslick',
                },
            ],
        });
    }

    sliderLftPhoto();

    $('.slider__tovars121').slick({
        dots: false,
        arrows: true,
        slidesToShow: 4,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: 'unslick',
            },
        ],
    });

    $('.round-blocks-999').slick({
        infinite: false,
        slidesToShow: 10,
        // edgeFriction: 0,
        // touchThreshold: 10,
        swipeToSlide: true,
        // variableWidth: true,
        cssEase: 'ease',
        speed: 800,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 7,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });

    let roundBlocks = $('.round-blocks-999');
    if (roundBlocks.length) {
        let segments = window.location.pathname.split('/');
        let lastSegment = segments.pop() || segments.pop();

        let slide = roundBlocks
            .find(".slick-slide a[href*='" + lastSegment + "']")
            .closest('.slick-slide');

        if (slide.length) {
            let slideIndex = slide.data('slick-index');

            let slidesToShow = roundBlocks.slick('slickGetOption', 'slidesToShow');
            let totalSlides = roundBlocks.slick('getSlick').slideCount;
            if (slideIndex > totalSlides - slidesToShow) {
                slideIndex = totalSlides - slidesToShow;
            }

            roundBlocks.slick('slickGoTo', slideIndex);
        } else {
            console.log('Slide not found for segment:', lastSegment);
        }
    }

    $('.slider__tovars').slick({
        dots: false,
        arrows: true,
        slidesToShow: 5,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: 'unslick',
            },
        ],
    });

    $('.banner__slider').slick({
        dots: false,
        arrows: false,
        autoplay: true,
    });

    $('.simile-slider').slick({
        dots: false,
        arrows: false,
        slidesToShow: 4,
        speed: 1,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1026,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });

    $('.simile-slider-settings').slick({
        dots: false,
        arrows: false,
        slidesToShow: 4,
        speed: 1,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1026,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });

    $('.simile-slider-settings').slick('slickSetOption', 'draggable', false);
    $('.simile-slider-settings').slick('slickSetOption', 'swipe', false);

    $(document).on('click', '.arrow-rht', function () {
        $('.simile-slider').slick('slickNext');
    });

    $(document).on('click', '.arrow-lft', function () {
        $('.simile-slider').slick('slickPrev');
    });

    $('.shops__block')
        .slick({
            dots: false,
            arrows: true,
            slidesToShow: 5,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        }).on('setPosition', function (event, slick) {
        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });

});